
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TicketsSidebar from '@/components/pages/tickets/TicketsSidebar.vue'
import TicketsGettingStartedSidebar from '@/components/pages/tickets/TicketsGettingStartedSidebar.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    TicketsSidebar,
    TicketsGettingStartedSidebar,
  },
  setup() {
    const { isEmptyHalfMode } = useModes()

    return {
      isEmptyHalfMode,
    }
  },
})
