
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import { ticketsStartingSidebarRoutes, ticketsStartingPersonalSidebarRoutes, ticketsGettingsStartedSidebarRoute } from '@/router/sidebarRoutes/ticketsRoutes'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import { useTicketsGettingStarted } from '@/compositions/tickets/useTicketsGettingsStarted'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'

export default defineComponent({
  components: {
    TmButton,
    PageContentSidebarList,
    PageContentSidebarHr,
  },
  setup() {
    const { openModal } = useModals()
    const router = useRouter()
    const { hideGettingStartedTab, getGettingStartedState, getOpenLink, tooltipState } = useTicketsGettingStarted()
    const { openNewFilteredView, openDeleteFilteredView } = useTicketsOpenModals()

    onBeforeRouteLeave(() => {
      tooltipState.value = false
    })

    const handleClose = () => {
      openModal('confirmation', {
        title: 'Hide getting started page',
        text: [
          'You are about to remove the ',
          {
            text: 'Getting started',
            style: 'semi-bold',
          },
          ' page from the sidebar. This action will remove it from your interface but don\'t worry, you can still find everything on the ',
          {
            text: 'Settings',
            style: 'semi-bold',
          },
          ' page.\n\nAre you sure you want to hide the Getting started page?',
        ],
        btnText: 'Hide',
        btnColor: 'primary',
        clickEvent: () => {
          router.push(getOpenLink.value.route!)
          hideGettingStartedTab()
        },
      })
    }

    const actionsMap: Record<string, () => void> = {
      duplicate: () => openModal('duplicate', {
        originalName: 'Open email requests',
        itemName: 'filtered view',
        showSharing: true,
        showEmoji: true,
      }),
      edit: () => router.push({ name: 'base.tickets.settings.filteredViews.detailed' }),
      delete: () => openDeleteFilteredView(),
    }

    const actionClick = (name: string) => {
      if (actionsMap[name]) actionsMap[name]()
    }

    return {
      actionClick,
      ticketsStartingPersonalSidebarRoutes,
      ticketsStartingSidebarRoutes,
      ticketsGettingsStartedSidebarRoute,
      openNewFilteredView,
      getGettingStartedState,
      handleClose,
    }
  },
})
