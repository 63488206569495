
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import { ticketsSidebarRoutes, ticketsSystemSidebarRoutes } from '@/router/sidebarRoutes/ticketsRoutes'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    TmButton,
    PageContentSidebarList,
    PageContentSidebarHr,
  },
  setup() {
    const { openModal } = useModals()
    const { openNewFilteredView, openDeleteFilteredView } = useTicketsOpenModals()
    const router = useRouter()

    const actionsMap: Record<string, () => void> = {
      duplicate: () => openModal('duplicate', {
        originalName: 'Open email requests',
        itemName: 'filtered view',
        showSharing: true,
        showEmoji: true,
      }),
      edit: () => router.push({ name: 'base.tickets.settings.filteredViews.detailed' }),
      delete: () => openDeleteFilteredView(),
    }

    const actionClick = (name: string) => {
      if (actionsMap[name]) actionsMap[name]()
    }

    return {
      actionClick,
      openNewFilteredView,
      ticketsSidebarRoutes,
      ticketsSystemSidebarRoutes,
    }
  },
})
